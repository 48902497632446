// colors
$background: #282c34;
$body-background: #d7d8da;
$primary: #955E42;
$secondary: #9C914F;
$tertiary: #748E54;
$fourth: #B1F8F2;

$text-primary: white;
$text-secondary: grey;
$text-link: #61dafb;

// padding
$padding-sm: 4px;
$padding-md: 8px;
$padding-lg: 16px;

// margin
$margin-sm: 4px;
$margin-md: 8px;
$margin-lg: 16px;

// font size
$font-xs: 10pt;
$font-sm: 12pt;
$font-md: 14pt;
$font-lg: 16pt;

// window sizes
$sv: 1000px;
$lv: 1001px;

// scorecard
$ace: gold;
$condor: rgb(6, 45, 78);
$albatross: rgb(21, 78, 148);
$eagle: rgb(42, 103, 182);
$birdie: rgb(129, 177, 240);
$bogey: rgb(226, 187, 167);
$doubleBogey: rgb(206, 124, 86);
$overDoubleBogey: rgb(235, 104, 44);
