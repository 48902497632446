@import "../../globalStyles.scss";

@media (min-width: $lv) {
  .addScorecard {
    .MuiFormControl-root {
      width: 100%;

      .formItem {
        margin-top: $margin-md;
      }
    }

    .submitButton {
      margin-top: $margin-md;
    }

    .parInput {
      width: 100px;
      margin: 5px;
    }
  }
}


@media (max-width: $sv) {
  .addScorecard {
    .MuiFormControl-root {
      width: 100%;

      .formItem {
        margin-top: $margin-md;
      }
    }

    .submitButton {
      margin-top: $margin-md;
    }
  }
}