@import "../../globalStyles.scss";

.awardRow:not(:last-child) {
  margin-bottom: 10px;
}

.addButton {
  @media (min-width: $lv) {
    margin-right: 5px;
  }
}
