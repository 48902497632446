@import "../../globalStyles.scss";

@media (min-width: $lv) {
  .currentRankings {
    .rankingsTable {
      width: 350px;
      text-align: center;
      margin: 0 auto;

      td {
        text-align: center;
      }

      .green {
        background-color: #3cb043
      }

      .red {
        background-color: #bc544b;
      }
    }
  }

  .graph {
    width: 100%;
    height: 500px;
    margin-bottom: 50px;
  }

  .getImg {
    display: none !important;
  }

  .statsBox {
    background-color: white;
    width: 50%;
    height: 50%;
    top: 25%;
    left: 25%;
    position: absolute;
    border-radius: 10px;
    padding: $padding-lg;
  }

  .closeBtn {
    position: absolute;
    top: 26%;
    right: 26%;
    z-index: 100;
  }
}

@media (max-width: $sv) {
  .currentRankings { 
    .rankingsTable {
      width: 100%;
    }

    .getImg {
      margin: 10px auto;
      width: 165px;
    }
  }

  .statsBox {
    background-color: white;
    width: 90%;
    height: 90%;
    top: 5%;
    left: 5%;
    position: absolute;
    border-radius: 10px;
    padding: $padding-lg;
  }

  .closeBtn {
    position: absolute;
    top: 26%;
    right: 26%;
    z-index: 100;
  }
}

.moreStats {
  text-align: center;
  margin-top: 10px;
}