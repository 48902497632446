#footer {
  height: 50px;
  text-align: center;

  a {
    text-decoration: none;
    color: black;
    font-weight: 400;
  }
}
